export default {
  fein: "465-45-6546",
  email: "aaa@metal.com",
  firstName: "Musah",
  lastName: "malik",
  agencyName: "Malik Agency",
  entityType: "LLC",
  physicalAddress: {
    city: "Accra",
    state: "Greater Accra",
    zipCode: "00233",
    streetName: "North",
    country: "Togo",
    streetDirection: "South",
    houseNumber: "641516454",
    county: "Altas",
    streetType: "ALLEY"
  },
  mailingAddress: {
    city: "Accra",
    state: "Greater Accra",
    zipCode: "00233",
    streetName: "North",
    country: "Togo",
    streetDirection: "North",
    houseNumber: "546545645",
    county: "Altas",
    streetType: "ALLEY"
  },
  phone: "888-999-8888",
  fax: "546456546",
  companyWebAddress: "http://malik.com",
  ownersName: "Malik",
  ownersFirstName: "Abdul",
  ownersLastName: "Malik",
  ownersEmail: "me@gmail.com",
  supervisorsEmail: "he@gmail.com",
  mainPersonalLinesContact: "744-899-6565",
  commercialBusinessSplit: 45,
  personalBusinessSplit: 34,
  totalNumberOfAgencyEmployees: 34,
  totalNumberOfPersonalLineStaff: 6,
  totalCombinedYearsForPersonalLinesStaff: 9,
  motiveForSeekingAppointment: "bla bla",
  factorsInfluencingPlacementOfResidentialPropertyBusiness: "bla bla bla",
  primaryFocusWhenSellingPropertyPolicy: "bla bla bal",
  top4ResidentialPropertyCarriers: ["nigeria", "togo", "Ghana", "India"],
  personalLinesPremiumVolumeInLast6Months: 8,
  anyMarketsAccessedThroughMGA: false,
  marketsAccessedThroughMGA: "Market",
  atlasWillReplaceCurrentCarrier: false,
  currentCarrier: "somthing",
  agencyUsesComparativeRater: false,
  comparativeRaterUsedByAgency: "something",
  familiarResidentialProductForms: "bla bla bla",
  howOurMarketFitsWithOtherMarketsAgentRepresents: "bla bla bla",
  applicationsPerMonth: 29,
  firstYearPremiumCommitment: 9090,
  marketingPlans: [
    "Have effective cross-sell program",
    "Media advertising",
    "Book roll"
  ],
  createdOn: new Date(),
  deletedBy: "",
  deleted: false,
  _id: "",
  modifiedOn: new Date()
};
